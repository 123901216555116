import React, { useState } from "react"
import { loadStripe } from "@stripe/stripe-js"
import FastAPIClient from "../../../../auth/fast_api_auth";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


const buttonStyles = {
  fontSize: '13px',
  textAlign: 'center',
  color: '#000',
  padding: '12px 60px',
  boxShadow: '2px 5px 10px rgba(0,0,0,.1)',
  backgroundColor: 'rgb(255, 178, 56)',
  borderRadius: '6px',
  letterSpacing: '1.5px',
}

const buttonDisabledStyles = {
  opacity: '0.5',
  cursor: 'not-allowed',
}

const Checkout = ({ school, course }) => {
  // TODO: optimize promises
  console.log(course)
  const [loading, setLoading] = useState(false)
  const schoolExternalId = school.external_id;
  let client = new FastAPIClient(schoolExternalId);

  const redirectToCheckout = async event => {
    event.preventDefault()
    // stripe checkout redirects will fail from the iframe preview
    if (school.isPreview) {
      alert('checkout is not permitted in preview mode');
      return;
    }
    setLoading(true)
    try {
      let checkoutSession;
      // fetch stripe or paddle checkout session
      checkoutSession = await client.createEnrollment(course)
      console.log(checkoutSession);
      if(checkoutSession?.payment_provider_type === "stripe"){
        // Get identifiers required for stripe js client
        const schoolStripeAccountID = await client.getSchoolStripeID();
        const platformStripeId = await client.getPlatformStripePK();
        // Initialise stripe client
        const stripe = await loadStripe(platformStripeId, {stripeAccount: schoolStripeAccountID});
        // now call stripe checkout session with this session ID
        // once the payment goes through use the refresh token endpoint to get a new JWT
        const { error } = stripe.redirectToCheckout({
          sessionId: checkoutSession.session_id
        }).then(function(result){
          console.log(result)
          if(result?.error?.message) {
            toast.error("There was an error processing your payment, please try again. If the problem continues" +
                "please contact the school owner");
          }
        })
      }else if(checkoutSession?.payment_provider_type === "paddle"){
        // Get identifiers required for paddle js client
        const schoolPaddleVendorID = await client.getPaddleVendorID();
        // Initialise paddle client
        Paddle.Setup({vendor: schoolPaddleVendorID})
        // now call paddle checkout session with this paddle_checkout_url
        Paddle.Checkout.open({
          override: checkoutSession.paddle_checkout_url
        });
      }else{
        setLoading(false);
        throw Error("Unknown payment_provider="+checkoutSession?.payment_provider)
      }
    } catch(err) {
      toast.error("There was an error on the server, please try again. If the problem continues" +
          "please contact the school owner");
      setLoading(false);
      throw err
    }
    setLoading(false);
  }

  return (
    <button
      disabled={loading}
      style={
        loading ? { ...buttonStyles, ...buttonDisabledStyles } : buttonStyles
      }
      onClick={redirectToCheckout}
    >
      Purchase Course
    </button>
  )
}

export default Checkout